import YouTube, { YouTubePlayer } from "react-youtube";

interface YouTubeEmbedProps {
	videoId: string;
	timestamp?: string;
	title?: string;
	className?: string;
	onReady?: (player: YouTubePlayer) => void;
}

export function YouTubeEmbed({
	videoId,
	timestamp,
	title,
	className = "",
	onReady,
}: YouTubeEmbedProps) {
	return (
		<YouTube
			videoId={videoId}
			title={title || "YouTube video player"}
			className={`aspect-video w-full rounded-lg overflow-hidden ${className}`}
			onReady={(event: any) => onReady?.(event.target)}
			opts={{
				playerVars: {
					rel: 0,
				},
				allow:
					"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
				allowFullScreen: true,
				width: "100%",
				height: "100%",
			}}
		/>
	);
}
