import { Link } from "@remix-run/react";
import { Home, Music, Users, Clock } from "lucide-react";

export default function Footer() {
	const navigation = {
		main: [
			{ name: "ホーム", href: "/", icon: Home },
			{ name: "曲を探す", href: "/songs", icon: Music },
			{ name: "歌手一覧", href: "/singers", icon: Users },
			{ name: "配信一覧", href: "/streams", icon: Clock },
		],
		legal: [
			{ name: "このサイトについて", href: "/about" },
			{ name: "プライバシーポリシー", href: "/privacy" },
			{ name: "利用規約", href: "/terms" },
			{ name: "ヘルプ", href: "/help" },
			{ name: "お問い合わせ", href: "/contact" },
		],
	};

	return (
		<footer className="bg-white border-t border-gray-200">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
				{/* メインコンテンツ */}
				<div className="grid grid-cols-1 md:grid-cols-4 gap-8">
					{/* ブランド情報 */}
					<div className="col-span-1 md:col-span-2">
						<Link to="/" className="flex items-center">
							<Music className="h-8 w-8 text-blue-500" />
							<span className="ml-2 text-xl font-bold text-gray-900">
								VSinger Moment
							</span>
						</Link>
						<p className="mt-4 text-base text-gray-500">
							推しの歌声と、めぐり逢う
						</p>
					</div>

					{/* ナビゲーション */}
					<div>
						<h3 className="text-sm font-semibold text-gray-500 tracking-wider uppercase">
							サービス
						</h3>
						<ul className="mt-4 space-y-4">
							{navigation.main.map((item) => (
								<li key={item.name}>
									<Link
										to={item.href}
										className="flex items-center text-base text-gray-600 hover:text-gray-900"
									>
										<item.icon className="h-4 w-4 mr-2" />
										{item.name}
									</Link>
								</li>
							))}
						</ul>
					</div>

					{/* 法的情報とその他リンク */}
					<div>
						<h3 className="text-sm font-semibold text-gray-500 tracking-wider uppercase">
							法的情報
						</h3>
						<ul className="mt-4 space-y-4">
							{navigation.legal.map((item) => (
								<li key={item.name}>
									<Link
										to={item.href}
										className="text-base text-gray-600 hover:text-gray-900"
									>
										{item.name}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>

				{/* フッターボトム */}
				<div className="mt-8 pt-8 border-t border-gray-200">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="flex items-center space-x-6"></div>
						<p className="mt-4 md:mt-0 text-base text-gray-500">
							<span className="flex items-center justify-center">
								VSinger Moment &copy; {new Date().getFullYear()}
							</span>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
