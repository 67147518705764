import { Link } from "@remix-run/react";
import { Music } from "lucide-react";

export default function Header() {
	return (
		<header className="bg-white border-b border-gray-200 fixed top-0 left-0 right-0 z-50">
			<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex items-center justify-between h-16">
					{/* ロゴ部分 */}
					<Link to="/" className="flex items-center">
						<Music className="h-8 w-8 text-blue-500" />
						<span className="ml-2 text-xl font-bold text-gray-900">
							VSinger Moment
						</span>
					</Link>

					{/* ナビゲーション */}
					<nav className="hidden md:flex items-center space-x-8">
						<Link
							to="/singers"
							className="text-gray-600 hover:text-gray-900 font-medium"
						>
							歌手一覧
						</Link>
						<Link
							to="/songs"
							className="text-gray-600 hover:text-gray-900 font-medium"
						>
							曲を探す
						</Link>
						<Link
							to="/streams"
							className="text-gray-600 hover:text-gray-900 font-medium"
						>
							配信一覧
						</Link>
					</nav>
				</div>
			</div>
		</header>
	);
}
