import { useEffect } from "react";
import { useLocation } from "@remix-run/react";

declare global {
	interface Window {
		/* trunk-ignore(eslint/@typescript-eslint/no-explicit-any) */
		gtag: (...args: any[]) => void;
	}
}

type TrackingProps = {
	gaTrackingId: string;
};

export default function GoogleAnalytics({ gaTrackingId }: TrackingProps) {
	const location = useLocation();

	useEffect(() => {
		if (gaTrackingId?.length) {
			window.gtag("config", gaTrackingId, {
				page_path: location.pathname + location.search,
			});
		}
	}, [gaTrackingId, location]);

	if (!gaTrackingId?.length) {
		return null;
	}

	return (
		<>
			<script
				async
				src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
			></script>
			<script
				dangerouslySetInnerHTML={{
					__html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gaTrackingId}');
          `,
				}}
			/>
		</>
	);
}
