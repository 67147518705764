import { useVideoPlayer } from "~/contexts/VideoPlayerContext";
import { YouTubeEmbed } from "~/components/shared/embeds/YouTubeEmbed";

export function GlobalVideoPlayer() {
	const {
		isPlayerVisible,
		setIsPlayerVisible,
		player,
		setPlayer,
		currentVideo,
	} = useVideoPlayer();

	if (!currentVideo) return null;

	return (
		<>
			{/* 上部プレイヤー */}
			<div className="fixed top-16 left-0 right-0 bg-white border-b border-gray-200 shadow-lg z-50">
				<div className="max-w-7xl mx-auto">
					<button
						onClick={() => setIsPlayerVisible(!isPlayerVisible)}
						className="w-full p-2 flex items-center justify-between hover:bg-gray-50"
					>
						<span className="text-sm font-medium text-gray-700">
							{currentVideo.title}
						</span>
						<span className="text-gray-400">{isPlayerVisible ? "▲" : "▼"}</span>
					</button>

					<div className={`aspect-video ${isPlayerVisible ? "" : "hidden"}`}>
						<YouTubeEmbed
							key={currentVideo.videoId}
							videoId={currentVideo.videoId}
							title={currentVideo.title}
							className="w-full h-full"
							onReady={(player) => {
								setPlayer(player);
								if (currentVideo.timestamp) {
									player.seekTo(currentVideo.timestamp, true);
									player.playVideo();
								}
							}}
						/>
					</div>
				</div>
			</div>

			{/* 下部コントロール */}
			<div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
				<div className="max-w-7xl mx-auto p-2 flex justify-center items-center gap-4">
					<button
						onClick={() => player?.playVideo()}
						className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
					>
						再生
					</button>
					<button
						onClick={() => player?.pauseVideo()}
						className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
					>
						一時停止
					</button>
				</div>
			</div>
		</>
	);
}
